import Component from '../../util/Component';
import config from '../../config';
import PropTypes from 'prop-types';

export default class extends Component {
  static propTypes = {
    returnUri: PropTypes.string,
  };

  render() {
    let state = '';
    let query = '';
    if (this.props.returnUri) {
      state = `?state=${new Buffer(
        JSON.stringify({
          back: this.props.returnUri,
        })
      ).toString('base64')}`;
      query = `?back=${this.props.returnUri}`;
    }

    return (
      <div className="columns">
        <div className="column col-4 col-sm-12 mt-2">
          <a
            data-test="google-login"
            className="btn btn-block"
            href={`/auth/google${state}`}
          >
            <img
              className="icon mr-4"
              style={{ marginLeft: '-.4rem' }}
              src={`${config.cdnHost}${config.cdnPath}/google-icon-2x.png`}
            />
            Google
          </a>
        </div>
        <div className="column col-4 col-sm-12 mt-2">
          <a
            data-test="docusign-login"
            className="btn btn-block"
            href={`/auth/docusign${state}`}
          >
            <img
              className="icon mr-4"
              style={{ marginLeft: '-.4rem' }}
              src={`${config.cdnHost}${config.cdnPath}/docusign.png`}
            />
            DocuSign
          </a>
        </div>
        <div className="column col-4 col-sm-12 mt-2">
          <div className="dropdown dropdown-right d-block">
            <a className="btn btn-block dropdown-toggle" tabIndex="0">
              MLS
              <i className="ml-2 icon icon-caret icon-right" />
            </a>
            <ul className="menu" style={{ width: '100%' }}>
              <li className="menu-item">
                <a href={`/auth/sfar${query}`} target="_self">
                  SFAR MLS
                </a>
              </li>
              <li className="menu-item">
                <a href={`/auth/bareis${query}`} target="_self">
                  BAREIS MLS
                </a>
              </li>
              <li className="menu-item">
                <a href={`/auth/eastbay${query}`} target="_self">
                  bridgeMLS
                </a>
              </li>
              <li className="menu-item">
                <a href={`/auth/eastbay${query}`} target="_self">
                  Bay East MLS
                </a>
              </li>
              <li className="menu-item">
                <a href={`/auth/eastbay${query}`} target="_self">
                  CCAR MLS
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
