import Component from '../../util/Component';
import Link from 'next/link';
import Contact from '../Base/Contact';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
export default class extends Component {
  static propTypes = {
    flavor: PropTypes.string,
    returnUri: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = { thinking: false };
  }

  onClickContinueAsUser = () => {
    this.setState({ thinking: true });
  };

  onClickSwitchUser = async () => {
    await this.$.Auth.logout();
  };

  render() {
    let continueLink = '/app/authed';
    if (this.props.returnUri) {
      continueLink = this.props.returnUri;
    }

    return (
      <div data-test="logged-in-already">
        <h3>{this.props.flavor == 'login' ? 'Login' : 'Sign up'}</h3>
        <p>You are already logged in.</p>
        <div className="panel bg-gray p-4">
          <Contact
            name={this.$.user.name}
            imageUrl={this.$.user.profileImageUri}
            secondaryText={this.$.user.email}
          />
        </div>
        <br />
        <Link href={continueLink}>
          <button
            data-test="logged-in-already-continue"
            className={`btn btn-primary btn-block mb-4 text-ellipsis ${
              this.state.thinking ? 'loading' : ''
            }`}
            onClick={this.onClickContinueAsUser}
          >
            Continue as {this.$.user.name}
          </button>
        </Link>
        <button
          data-test="logged-in-already-switch"
          className="btn btn-block"
          onClick={this.onClickSwitchUser}
        >
          {this.props.flavor == 'login' ? 'Login' : 'Sign up'} as another user
        </button>
      </div>
    );
  }
}
